import React, { useState } from "react";
import {
    Arpit_k,
    Swati_Bilaiya,
    avinash,

    thomas,
    vinu,
    riyanka,
    shagufta,
    sanjayRathore,
    rakshit,
    mohini,
    akash,
    Agrim,
    atharva,

    
    mohib,
    Dikshitsuthar
} from "../../../../assets";
import { Committee } from "./Committee/Committee";

export const People = ({ index }) => {
    const [committee1] = useState({
        // title: "Office of International Affairs & Outreach",
        title: "Office of International Relations",
        members: [
            [
                {
                    name: "Prof. Avinash Sonawane",
                    designation: "Dean",
                    email: "doir@iiti.ac.in",
                    img: avinash,
                    phno: "+91-731-660-3357",
                },
            ],
            // [
            //     {
            //         name: "Prof.  Raghunath Sahoo",
            //         designation: "Associate Dean",
            //         email: "adoir@iiti.ac.in",
            //         img: raghu,
            //         phno: "+91-731-660-3329",
            //     },
            //     {
            //         name: "Prof. Sanjay Kumar Singh",
            //         designation: "Associate Dean",
            //         email: "adoir2@iiti.ac.in",
            //         img: sanjay,
            //         phno: "+91-731-660-3350",
            //     },
            // ],
            [
                {
                    name: "Mr. Rajan Thomas",
                    designation: "Assistant Registrar",
                    email: "arir@iiti.ac.in",
                    img: thomas,
                    phno: "+91-731-660-3112",
                },
            ],
                // {
                //     name: "Mr. Suresh Chandra Thakur",
                //     designation: "Assistant Registrar",
                //     email: "drintl@iiti.ac.in",
                //     img: suresh,
                //     phno: "+91-731-660-3150",
                // },
            //   [  
            //     {
            //         name: "Ms. Shagufta Rahim",
            //         designation: "Section Officer",
            //         email: "shagufta@iiti.ac.in",
            //         img: shagufta,
            //         phno: "+91-731-660-3109",
            //     },
            // ],
                [
                    {
                    name: "Dr. Arpit Kumar Shrivastava",
                    designation: "Global Engagement Executive Officer",
                    email: "geeo.ir\@iiti.ac.in",
                    img: Arpit_k,
                    phno: "+91-731-660-5540",
                },
            ],
            [
                {
                    name: "Mr. Vinay Kumar",
                    designation: "Junior Superintendent",
                    email: "intloffice@iiti.ac.in",
                    img: vinu,
                    phno: "+91-731-660-3479",
                },
               
                {
                    name: "Mr. Mohib Rahim",
                    designation: "Executive Officer - Academics",
                    email:"acaofficer.ir@iiti.ac.in",
                    img: mohib,
                    phno:"+91-731-660-3392",
                },
            ],
            [
                {
                    name: "Ms. Riyanka Sharma",
                    designation: "Junior Assistant",
                    email: "managerir@iiti.ac.in ",
                    img: riyanka,
                    phno: "+91-731-660-3573",
                },
                {
                    name: "Ms Swati Bilaiya",
                    designation: "Junior Assistant",
                    email: "managerir@iiti.ac.in ",
                    img:Swati_Bilaiya,
                },
            ],
            [  
                {
                    name: "Mr.Sanjay Rathore",
                    designation: "Attendant",
                    img:sanjayRathore,
                }

            ],
        ],
    });

    const [committee2] = useState({
        title: "Students' International Relations Cell",
        members: [
            [
                {
                    email: "me210003018@iiti.ac.in",
                    name: "Atharva Dhore",
                    phno: "+91 7263931615",
                    img: atharva,
                    designation: "Head",
                },
            ],
            [
                {
                    heading: "Coordinators",
                    email: "ce220004004@iiti.ac.in",
                    name: "Agrim Jain",
                    phno: "+91 9811085456",
                    img: Agrim,
                    designation: "Opportunities Team",
                },
                {
                    email: "ee220002068@iiti.ac.in",
                    name: "Rakshit Jangid",
                    phno: "+91 8849463566",
                    img: rakshit,
                    designation: "International Relations Team",
                },
            ],
            [
                {
                    email: "msc2203171012@iiti.ac.in",
                    name: "Mohini",
                    phno: "+91 9667033160",
                    img: mohini,
                    designation: "Outreach Team",
                },
                {
                    email: "mems220005019@iiti.ac.in",
                    name: "Dikshit suthar",
                    phno: "+91 8963843663",
                    img: Dikshitsuthar,
                    designation: "Web Team",
                   
                },
            ],
        ],
    });

    return (
        <div className="container-fluid">
            {index === 1 && <Committee committee={committee1} />}
            {index === 2 && <Committee committee={committee2} />}
        </div>
    );
};
