import {
  Songkla,
  Walailak,
  Thammasat,
  Educational_diplomacy,
  Mou,
  french_collaboration,
  chem_talks_news,
  ai,
  chemR,
  phyR,
  NSDImg,
  FrenchConsultImg,
  BUL,
  NIDB,
  MoU,
  virtualBooth,
  uoa,
  bala_ramani,
  midCareer,
  Hungarian,
  image4,
  riisshe,
  chelvamBordeaux,
  hud_uk,
  welcome_news,
  ntut,
  norwegianDelegates,
  fulbright_1,
  ananya,
  rwthUniv,
  sandeep,
  serb,
  HemaChandraJha,
  studyInFrance5Aug22,
  InternationalStudentsVisit,
  canadaVisitIITIndore,
  profSonawane,
  abhinavsir,
  sundararajan,
  talk1,
  talk2,
  visit14102022,
  nirmala,
  kwansei,
  israel,
  cheongming,
  uofa,
  tmsp,
  international_visit_sussexUK,
  jenish,
  MITACS2023,
  sigmtphd,
  grlafp,
  MoUJMU,
  Kanchan_Samadhiya,
  Puneet_gupta_image,
  Priya_Ghosh,
  Fullbright,
  BreakingStigma,
  Poching_Johnny,
  academic_program_23,
  PhdPgPdf,
  Sweny,
  indo_taiwan,
  avin,
  ajaykk,
  tanveer,
  anirban,
  neelima,
  Saptarshi,
  shomik,
  suman,
  vivek,
  aryan,
  btech_dubai,
  green_coin,
  sarika,
  IGSTC,
  temp,
  sharma,
  sharma1,
  freepress,
  Mukesh,
  nehal,
  Avinash_Sonawane,
  International_Vist,
  Miharu_Yui,
  daad_workshop,
  final_fellow,
  reivew_ir_team,
  newyear,
  studyInFrance31Jan24,
  NepalMou,
  tribhuvanmou,
  DigitalHumanities,
  ananyaghoshal,
  hirokisayama,
  vaishalisaini,
  shaurya,
  IR_New_year_2025,
  Japan_9feb,
  Japan_20feb,
  Ralamandal_Wildlife_Sanctuary,
} from "../../../../assets";

export const data = [
  {
    title: "Ms. Shaurya has been chosen to attend the prestigious 74th Lindau Nobel Laureate Meeting 2025",
    image:  shaurya,
    desc: "DST, New Delhi has nominated 29 Indian Students for participation in the 74th Lindau Nobel Laureate Meeting 2025. And Shaurya is one of them. The meeting will be held from 29th June to July 4th 2025 in Lindau, Germany. We congratulate her on this achievement."
  },
  {
    title: "Nature, Learning & Friendship: A Memorable Day at Ralamandal Wildlife Sanctuary 🌿🌏",
    image:  Ralamandal_Wildlife_Sanctuary,
    desc: "A day well spent with our international students at Ralamandal Wildlife Sanctuary on 22nd March 2025, where nature became our classroom and every moment was an opportunity to connect, explore, and appreciate the beauty around us. Beyond academics, such experiences foster cultural exchange, meaningful conversations, and a shared appreciation for the environment. Walking through the serene landscapes, spotting wildlife, and enjoying the tranquility of nature created memories that will stay with us for a long time.These outings not only provide a refreshing break from studies but also strengthen the bonds between students from diverse backgrounds, making learning truly global and experiential."
  },
  {
    title: "Fostering Academic Exchange: IIT Indore Hosts Esteemed Scholars from Japan 🇯🇵✨",
    image:  Japan_20feb,
    desc: "We welcomed Prof. Manish Biyani from Kwansei Gakuin University 🇯🇵, and Dr. Madhu Biyani from Kanazawa University, Japan for an insightful discussion at IIT Indore on 20th February 2025. Their expertise and dedication in their respective fields were truly inspiring. It was a pleasure to have them deliver engaging lectures to both students and faculty, sparking new ideas and academic curiosity."
  },
  {
    title: "Strengthening Global Ties: IIT Indore Delegation Explores Collaboration with Hiroshima University",
    image:  Japan_9feb,
    desc: "The IIT Indore delegation, led by our esteemed Director, visited Japan from 9th to 15th February 2025. They visited Hiroshima University and had the privilege of meeting Professor Mitsuo Ochi, President of the university there. During their visit, they engaged in a productive discussion 🤝 on potential future collaborations and academic exchanges between our institutions. We’re looking forward to the incredible opportunities this partnership will bring! 🌍."
  },
  {
    title: "New Year Celebration with the International students at IR Office: Goodbye 2024! Welcome 2025!",
    image:  IR_New_year_2025,
    desc: "The International Relations Office at IIT Indore had the privilege of celebrating the New Year with our vibrant international students community! 🌟 Together, we shared laughter, cultural exchange, and the warmth of a truly global family.As we step into 2025, we are reminded that diversity is our strength and collaboration is the key to unlocking new opportunities. Here's to another year of learning, growing, and building bridges across borders! 🌍✨."
  },
  {
    title: "Ms. Vaishali Saini has been awarded with the Raman Charpak Scholarship",
    image:  vaishalisaini,
    desc: "The Raman-Charpak Fellowship programme is in honour of two Nobel Laureates in Physics, Prof. C.V. Raman, Indian Nobel Laureate (1930) and Prof. Georges Charpak, French Nobel Laureate (1992). We are thrilled to announce that from IIT Indore, Ms. Vaishali Saini, a PhD student from BSBE, has been selected for this fellowship. We congratulate her on this incredible achievement."
  },
  {
    title: "Introduction to Systems Science: Interdisciplinary Graduate Study Opportunity at Binghamton University, State University of New York",
    image:  hirokisayama,
    desc: "IIT Indore is delighted to announce a special talk by Prof. Hiroki Sayama, Director of the Binghamton Center of Complex Systems (CoCo) at Binghamton University, State University of New York, USA. The event will highlight opportunities for interdisciplinary graduate studies abroad.Prof. Sayama will discuss Systems Science, a dynamic field emphasizing system-based modeling and analysis. Attendees will gain insights into career opportunities in STEM and beyond, alongside real-world applications like pandemic responses.                                                                                        Date - January 13 , 2025 , Time -  2:30-3:00 pm IST, Venue - POD 1D-105 , Tea Break: 3:00-3:10 pm IST."
  },
  {
    title: "Dr. Ananya Ghoshal to Lead Climate-Conscious English Teaching Course as Alumni Facilitator",
    image:  ananyaghoshal,
    desc: "Dr. Ananya Ghoshal, nominated by the U.S. Embassy, completed Kansas State University's professional development course, 'Teaching for the Future: English Teaching to Take on Climate Change,' with academic distinction. As the only Indian alumnus chosen, Dr. Ghoshal joins 33 peers from 24 countries as an Alumni Facilitator for the course's new iteration, 'Teaching English for Environmental Awareness,' on the OPEN MOOC platform. Running from October to November 2024, the course equips English teachers to address climate change and environmental issues through their curriculum."
  },
  {
    title: "Seminar on Digital Humanities and Infrastructures: A Collaborative Success",
    image:  DigitalHumanities,
    desc: "The Office of International Relations, IIT Indore, in collaboration with JPN National Centre of Excellence in the Humanities and Freie Universität Berlin, organized a 1.5-day seminar on 'Digital Humanities and Infrastructures.' Scholars and students explored the intersection of digital technologies and humanities, discussing tools, archives, and theory. The event fostered dynamic dialogues on the evolving role of technology in humanities research, making it a resounding success."
  },
  {
    title: "IIT Indore signed MoU with Tribhuvan University, Nepal",
    image:  tribhuvanmou,
    desc: "In a step towards forging stronger international ties, IIT Indore has signed an MoU with Tribhuvan University in Nepal. This partnership promises to bring exciting new opportunities for academic exchanges and groundbreaking research. By working together, both institutions aim to innovate and build a future where education transcends borders."
  },
  
  {
    title: " IIT Indore signed MoU with Mid-west university, Nepal",
    image:  NepalMou,
    desc: "IIT Indore has signed an MoU with Mid-west university, Nepal, marking a significant step toward enhancing academic exchanges, fostering research partnerships, and promoting innovation. This collaboration aims to strengthen ties between the two institutions, encouraging shared knowledge, resources, and a commitment to building a brighter, more innovative future."
  },
  {
    title: " Session on Study opportunities in France",
    image:  studyInFrance31Jan24,
    desc: "On Jan 31st Dr. Phillipe Maurin, the Attaché for Scientific and Academic Cooperation for West India at the Consulate of France in India  along with Ms Divya Saxena from Campus France and Ms Amruta Datar visited IIT Indore and conducted a session on Study opportunities in France."
  },

  {
    title: "Global Harmony: International Students Ring in the New Year with Prof. Avinash Sonawane and Team IR at IIT Indore",
    image: newyear,
    desc: "International Relations IIT Indore celebrated the new year along with Prof. Avinash Sonawane, Dean of International Relations and Team IR, where they also enjoyed some fun activities and cultural Interaction."
  },
  {
    title: "IIT Indore Eyes Global Expansion: Director Reviews International Relations Progress",
    image: reivew_ir_team,
    desc: "On December 19, 2023 Prof. Suhas Joshi, Director, IIT Indore reviewed the progressive work of International Relations"
  },
  {
    title:"Exploring Collaborative Avenues: IIT Indore Engages with French Consulate in Mumbai",
    image:french_collaboration,
    desc: " On 17th November 2023, Prof. Suhas Joshi, Director IIT Indore and Prof. Avinash Sonawane, Dean International Relations visited French Consulate In Mumbai and discussed opportunities for collaborations between IIT Indore and French institutes with Mr.Sere Charlet, Counsel General of France in Mumbai, Dr. Philippe Maurin, Science and Higher Education Attache, Ms. Amruta Datar , Deputy-Attache for Scientific and Academic Cooperation.",
    name: "french_collaboration",
  },
  {
    title: "Institute's Fellowship Triumph: Honoring Exceptional Faculties and Students 🆕",
    image: image4,
    desc : ()=> (
      <p>
         The institute has launched 05 Fellowship programs to provide excellent opportunities for faculty members and students to promote collaborations as well as to perform high-quality research at reputed institutes abroad, or at IIT Indore.
         <br/><br/>
         <b>Fellowship programs:</b>
         <ol>
          <li>International Travel Grants for faculty members of IIT Indore</li>
          <li>Short-term Research Fellowship program for IIT Indore PhD (Outbound)</li>
          <li>Short-term Research Fellowship program for foreign PhD students (Inbound) </li>
          <li>Semester Exchange Fellowship Program for IIT Indore UG/PG students (Outbound)</li>
          <li>Semester Exchange Fellowship Program  for foreign UG/PG Students (Inbound)</li>
         </ol>
         The list of awardees is attached <a href={final_fellow} target="_blank" rel="noreferrer">Here</a>
      </p>
    
    ),
  },
  {
    title: "DAAD iHED WorkShop",
    image: daad_workshop,
    desc: "International Relations,  IIT Indore organized a 2 day DAAD iHEDworkshop - Internationalization at Indian Higher Education institutions- structures and services of the German Academic Exchange Service (DAAD) on October 30 and 31, 2023. The workshop was honored by the presence of Prof Suhas.S.Joshi, Director, IIT Indore, Mrs. Marja-Sirkha Einig, Deputy Consul General, German Consulate Mumbai, Dr. Katja Lasch, Director,  DAAD Regional Office, Nee Delhi, and Dr. Shikha Sinha, Senior Advisor, DAAD Office, New Delhi. About 25 participants from various Indian Higher education institutions across India participated in this workshop.",
  },
  {
    title: "Short-Term Research Fellowships for International PhD Students",
    image: image4,
    desc: "IIT Indore is thrilled to announce the availability of Short-Term Research Fellowships for International PhD Students, opening doors for scholars from around the world to engage in research collaboration with our esteemed institution. These fellowships are designed to provide international PhD students with a unique opportunity to work alongside our accomplished faculty members, access cutting-edge research facilities, and contribute to the vibrant academic environment at IIT Indore. By fostering global research partnerships, we aim to encourage cross-cultural learning and the exchange of innovative ideas, enriching the academic experience for both our students and the visiting scholars. These fellowships reflect our commitment to fostering international cooperation and further enhancing the research ecosystem at IIT Indore, contributing to a diverse and inclusive academic community. We eagerly look forward to welcoming talented scholars from abroad to join us in this exciting academic journey.",
    name: "srp-is",
    link: "/inbound/students/srp-is",
  },
  {
    title:"Japanese Delegation Explores Collaboration with IIT Indore in Humanities",
    image:Miharu_Yui,
    desc: "On September 4, 2023, Ms. Miharu Yui, Associate Professor and her team from Kwansei Gakuin University of Japan, paid a visit to IIT Indore for the potential Exchange and collabration, in the field of Humanities under the guidance of Professor Pritee Sharma."
  },
  {
    title: "International Students' Visit to IIT Indore in August 2023",
    image: International_Vist,
    desc: "30 International students from 13 different countries including Brazil, Azerbaijan, Egypt, UK, USA, Germany, Indonesia, Japan, Mexico, Netherlands, Hungary, South Africa, Thailand were facilitated to visit IIT Indore by the Office of International Relations, IIT Indore on 3rd August 2023 under Global BP STEM Academic program in association with American Field Service (AFS), India. The visit was coordinated by Emerald Heights International School, Indore which is an active member of AFS since 2012 under the Indore chapter (MP) India."
  },
  {
    title: "Prof. Avinash Sonawane appointed as Humboldt Visiting Professor at Leibniz Lung Centre, Germany.",
    image: Avinash_Sonawane,
    desc: "Prof. Avinash Sonawane, Dean of International Relations Office has been appointed as Humboldt Visiting Professor (Guest Professor) at Leibniz Lung Centre, Germany.",
    link: "https://streaklinks.com/BmYB0Wen578cJPolUgsFuDU8/https%3A%2F%2Ffz-borstel.de%2Findex.php%2Fde%2Fsitemap%2Fprogrammbereich-infektionen%2Fzellulaere-mikrobiologie-prof-dr-ulrich-schaible%2Fmitarbeiter-innen%23innercontent"
  },
  {
    title: "Mr Nehal Ahmad, an exchange PhD student from National Taiwan University at IIT Indore was awarded a phd degree",
    image: nehal,
    desc: () => ( 
      <p>Mr. Nehal Ahmad, an exchange PhD student of Prof. KT Lai from NTU, Taiwan, successfully defended his thesis on July 07, 2023 under the co-supervision of Dr. M. Tanveer from the Department of Mathematics at IIT Indore. Mr. Nehal Ahmad worked at Dr. Tanveer's laboratory between March 2022 and March 2023, and has also published papers in the following reputed journals:<br/><br/>
        <ul>
        <li>IEEE Journal of Biomedical and Health Informatics (formerly known as IEEE Transactions on Information Technology in Biomedicine) [SCI Indexed with Impact Factor: 7.7]</li>
        <li>Information Fusion, Elsevier [SCI Indexed with Impact Factor: 18.6]</li>
        <li>Pattern Recognition, Elsevier [SCI Indexed with Impact Factor: 8] (Under Review)</li>
      </ul>
      One of his papers, which won the Best Research Paper Award, was also published in the 29th ICONIP in 2022.
      </p>
    
      ),
  },
  {
    title: "Prof. Mukesh Kumar received the Scientific High Level Visiting Fellowship (SSHN) to visit France",
    image: Mukesh,
    desc: "Prof. Mukesh Kumar from the Department of Electrical Engineering received the Scientific High Level Visiting Fellowship (SSHN) to visit France.  SSHN provides grants for researchers based on the excellence of the research project proposed and its importance for bilateral scientific cooperation to strengthen French-Indian research networks."
  },
  {
    title: "Dr. Ananya Ghoshal has been nominated by the U.S. Embassy to participate in an Online Professional English Network professional development course.",
    image: ananya,
    desc: "The U.S. Embassy has nominated Dr. Ananya Ghoshal to participate in an OPEN professional development course as a Primary Nominee during the Fall 2023 term with a competitive scholarship. The Online Professional English Network (OPEN) Program is sponsored by the U.S. Department of State, with funding provided by the U.S. government and administered by FHI 360. OPEN offers foreign English teaching professionals the opportunity to take innovative, online university-level classes. Ananya works on the Anthropocene and will participate in the course, 'Teaching for the Future: English Teaching to Take on Climate Change,' developed by Kansas State University."
  },
  {
    title: "Prof. Suhas Joshi, Director, IIT Indore attends the Global University Leaders’ Council (GUC) 2023 meet",
    image: freepress,
    desc: "Prof. Suhas Joshi, Director IIT Indore attended the Global University Leaders’ Council (GUC) 2023 meet during June 14-16, 2023 at Hamburg, Germany. This year’s meeting was focused on “Way forward for universities when navigating competition and collaboration locally, nationally, and globally”. He participated in the discussion along with other panelists on “Fostering global partnerships among universities: Jointly redressing growing populism and anti-intellectualism” and shared his views on the role of university leaders in popularizing research and education, and how universities should use academic freedom to improve the quality of education to address societal challenges. "
  },
  {
    title: "Dr. Hem Chandra Jha is elected as a Fellow (FRSB) of the Royal Society of Biology (RSB), UK by Council on 1 July 2023",
    image: temp,
    desc: "Dr. Hem Chandra Jha, Associate Professor at the Indian Institute of Technology Indore, has been elected as a Fellow of the Royal Society of Biology (FRSB). The Fellowship is the most prestigious grade of RSB membership, and is awarded to individuals who have made a significant contribution to the advancement of the biological sciences. \n\nDr. Jha is a leading expert in the field of plant molecular biology. His research has focused on the molecular mechanisms that regulate plant development and stress responses. He has published over 100 papers in peer-reviewed journals, and his work has been cited over 3,000 times. \n \n Congratulations to Dr. Jha on this well-deserved honor!"
  },
  
  {
    title: "39 IITI students got selected to receive the Shraman Foundation Scholarship",
    image: sharma,
    desc: () => ( 
    <p style={{ whiteSpace: "pre-wrap" }}> {" "} 
     The Shraman Foundation Scholarship is a significant achievement, and having 39 IITI students selected for it is a testament to their hard work and dedication. Scholarships like these can provide valuable financial support and recognition for students pursuing higher education. It's a remarkable accomplishment for both the students and the institution.To know more,
    <a href={sharma1} target="_blank" rel="noreferrer">
    Click Here
      </a>
    </p>
    ),
  },
  {
    title: "Dr. Hem Chandra Jha, Associate Professor in the Department of BSBE got the approval for his SPARC proposal #2018",
    image: temp,
    desc: "The SPARC Apex committee has approved the proposal titled, Concerted Epstein Barr Virus and astrocyte response in neuroinflammation favouring Multiple Sclerosis progression of Dr. Hem Chandra Jha for funding. This project is for two years.\n\n Congratulations to him on this achievement"
  },
  {
    title: "Director, IGSTC visited IIT Indore on June 28, 2023",
    image: IGSTC,
    desc: "IGSTC is a joint initiative by the Department of Science & Technology (DST),Government of India and the Federal Ministry of Education and Research (BMBF), Government of Germany  to facilitate Indo-German R&D networking  among government, academia/research systems. Through its various funding programs, IGSTC intends to catalyse innovation centric R&D projects by synergising the strength of research/academic institutions and public/private industries from India and Germany.\n \n Mr. R. Madhan, Director, Indo-German Science & Technology Centre (IGSTC), New Delhi visited IIT Indore on June 28 for an interaction with our faculty and research scholars about various funding schemes and fellowship programs that IGSTC offers. \n \n Previously many of our faculties and students have received support from IGSTC.  Hence, this interaction was indeed very beneficial for our attendees."
  },
  {
    title: "IITI Idea \"Green Coin\" Selected Among 75 to Promote Life",
    image: green_coin,
    desc: "In the global competition, \"LiFE Global Call for Ideas and Paper\" organized by NITI Aayog (Behavioural Insights Unit) and the Ministry of Environment, Forest and Climate Change (MOEFCC), in partnership with the United Nations, World Resources Institute, Centre for Social and Behaviour Change (CSBC) and Bill and Melinda Gates Foundation (BMGF), IITI teams' idea “Green Coin for Grassroot Behaviour Transformation: Pro-Environment Crowd-Actions and Improvement of National Quality of Life” has been recognized among the 75 ideas to promote LiFE. \n\n (Team: Dr. Sanjram Premjit Khanganba and Prof. Vimal Bhatia from IIT Indore, India)"
  },
  {
    title: "IEEE MTT-S Undergraduate Scholarship–2023 awarded to Mr. Aryan Rastogi",
    image: aryan,
    desc: "Mr. Aryan Rastogi, BTech student from the Department of Electrical Engineering has been selected for the IEEE MTT-S Undergraduate Scholarship – 2023 Cycle 1 Awards and received a scholarship of $1,500 for pursuing his research in areas of reconfigurable intelligent surfaces (RISs). This scholarship has been awarded to a total of 6 undergraduate students across the world working in the domain of microwave theory and techniques (MTT). We congratulate him on his achievement."
  },
  {
    title: "Overseas Visiting Doctoral Fellowship (OVDF) awarded to Mr. Vivek Saxsena",
    image: vivek,
    desc: "SERB-University of Alberta Overseas Visiting Doctoral Fellowship (OVDF) is awarded to a PhD student, Mr. Vivek Saxsena from the Department of Mechanical Engineering to carry out his doctoral research work at University of Alberta, Canada during January 2024- January 2025. (Supervisors: Prof. Santosh K. Sahu and Prof. Shailesh I. Kundalwal). We congratulate him on his achievement."
  },
  {
    title: "Dr. Suman Majumdar Receives SPARC Grant for Galaxy Evolution Research",
    image: suman,
    desc: "Dr. Suman Majumdar, Associate Professor at the Indian Institute of Astrophysics, has received a SPARC (Scheme for Promotion of Academic Research Careers) grant of 65 lakh INR (US$82,500) for her project titled \"Galaxies through the ages: using synergistic multi-wavelength observations.\" The SPARC grant is a prestigious award given by the Department of Science and Technology (DST), Government of India, to support early career researchers in their research endeavors.\n\nDr. Majumdar's project will use synergistic multi-wavelength observations to study the evolution of galaxies over cosmic time. The project will combine data from a variety of telescopes, including the Hubble Space Telescope, the Chandra X-ray Observatory, and the Atacama Large Millimeter/submillimeter Array. \n\nCongratulations to Dr. Majumdar on this well-deserved award!"
    
  },
  {
    title: "Dr Shomik Dasgupta was elected FRHistS, Fellow of the Royal Historical Society of Great Britain in December 2022,",
    image: shomik,
    desc:"Dr. Shomik Dasgupta, Assistant Professor at the Indian Institute of Technology Indore, has been elected as a Fellow of the Royal Historical Society (FRHistS). The Fellowship is the highest grade of membership of the Royal Historical Society, and is awarded to individuals who have made a significant contribution to historical scholarship.\n\nDr. Dasgupta is a leading expert in the history of modern India. His research has focused on the intellectual history, governance, and local administration of India in the 19th and 20th centuries. He has published over 10 papers in peer-reviewed journals, and his work has been cited over 500 times. \n\n Congratulations to Dr. Dasgupta on this well-deserved honor!"
    
  },
  {
    title: "Dr. Saptarshi Ghosh's research articles,received the prestigious Motohisa Kanda Award in 2022.",
    image: Saptarshi,
    desc: "Dr. Saptarshi Ghosh, Assistant Professor at the Indian Institute of Technology Indore, has received the prestigious Motohisa Kanda Award for his research article entitled \"Broadband Polarization-Insensitive Tunable Frequency Selective Surface for Wideband Shielding.\" for receiving the highest citations among all the papers published in the last 5 years (2017-2021) in IEEE Transactions on Electromagnetic Compatibility \n\n Congratulations to Dr. Ghosh on this well-deserved award!"
    
  },
  {
    title: "Prof. Neelima Satyam received JSPS BRIDGE Fellowship award.",
    image: neelima,
    desc:"Dr. Neelima Satyam,Professor in the Department of Civil Engineering at IIT Indore has recieved the JSPS BRIDGE Fellowship award in July 2023 from Kyushu University, Japan.\n \nCongratulations to Prof.Neelima on this well-deserved award!"
    
    
  },
  {
    title: "Dr Anirban Sengupta received various international awards:",
    image: anirban,
    desc: " 1) Distinguished Visitor Medal by IEEE Computer Society, USA in 2023 for outstanding scientific contributions.\n \n 2) Ranked in Stanford University's Top 2% Scientists worldwide across all domains and in Top 0.3 % Scientists worldwide in IT/Networking/Architecture domain.\n \n 3) His authored book published by IET in 2023 catalogued/showcased in the British Library.\n \n 4) He got reappointed in the Editorial Board, as Associate Editor, of IEEE Transactions on VLSI Systems (only Professor and Scientist from India on the board)."
  },
  {
    title: "Dr M. Tanveer received the 2022 APNNS Young Researcher Award.",
    image: tanveer,
    desc:"M. Tanveer,Associate Professor and Ramanujan Fellow at the Discipline of Mathematics of the Indian Institute of Technology,has recieved the 2022 APNNS Young Researcher Award. He is the only Indian to receive this award in the history of APNNS.\n\nCongratulations to Dr.Tanveer on this well-deserved award!"
    
  },
  {
    title: "Dr Ajay Kumar Kushwaha's has received BEST ORAL PRESENTATION Award. ",
    image: ajaykk,
    desc:"Dr Ajay Kumar Kushwaha's research paper entitled 'Electrodeposition of Ni-Co Alloy films for Hydrogen Evolution' has received BEST ORAL PRESENTATION AWARD in 11th Asia Conference on Mechanical and Materials Engineering (ACMME-2023) which was held during 8th to 11th June 2023 at Sapporo Japan .\n\nCongratulations to Dr.Ajay on this well-deserved award!"

    
  },
  {
    title: "Prof. Avinash Sonawane, Dean IR has been elected as a Steering and Executive Committee member of Heritage Network for a duration of four years.",
    image: avin,
    desc: "The HERITAGE NETWORK as it exists today is the result of several years of partnerships, meetings and projects between 30 top Indian and European Higher Education Institutions. On June 23 (1st and 2nd): 6th General Assembly of the Heritage Network was hosted by Universidad Politecnica de Madrid (Spain), where Prof. Avinash Sonawane, Dean IR presented on Development of Courses related to Sustainability. Also, he has been elected as one of the Steering and Executive Committee members of Heritage Network for a duration of four years."
  },
  {
    title: "IIT Indore signed an MoU with National Ilan University Taiwan on 19th of May 2023.",
    image: indo_taiwan,
    desc: "Prof. Poching Johnny Wu, the President of the National Ilan University Taiwan visited IIT Indore the previous month to discuss possible collaborations and exchange among the institutes, during their visit they also visited our research labs and infrastructure. \n \n As a consequence, Prof. Suhas S. Joshi, the Director of IIT Indore along with Prof. Avinash Sonawane, the Dean of International Relations then paid a visit to their institute and signed an MoU on 19th of May, 2023. We wish to enhance relations between the two institutions and to develop academic and cultural interchange in the areas of education, research, and other activities, and agree to cooperate and work together toward the internationalization of higher education through this partnership.",
  },
  {
    title: "Ms.Sweny Rokani visits IIT INDORE to talk about potential exchanges and collaborations between IITI and NUS",
    image: Sweny,
    desc:"On May 11, 20023, Ms. Sweny Rokani, who oversees the Indian continent for the Academic Programme Business Unit at the National University of Singapore (NUS), paid a visit to IIT Indore, International Relations Office to talk about potential exchanges and collaborations between the two institutions."
    
  },
  {
    title:
      "Call for applications from the International students for PG and PhD programs for the Academic Year 2023-2024",
    image: academic_program_23,
    desc: ()=>(
      <p>Applications are invited from highly motivated and research-oriented Internationl applicants for admission to PG and PhD Program in different specializations of different Departments for Academic year 2023-2024.
      <p>To know more, Click here</p>
  
   <a href={PhdPgPdf} target="_blank" rel="noreferrer">
  Program details
  </a>
      </p>
    )
    
  },

  



  {
    title: "Prof. Poching Johnny Wu visits IIT Indore",
    image: Poching_Johnny,
    desc:"Prof. Poching Johnny Wu, the President of the National Ilan University Taiwan along with five delegations visited IIT Indore on 26th of April to discuss possible collaborations and exchange among the institutes.",
    
  },
  
  {
    title: "Session on \"Fulbright Fellowship Opportunities to the U.S.\" by USIEF at IIT Indore was held on 12th of April, 2023",
    image: Fullbright,
    desc: "An interactive session on “Fulbright Fellowship Opportunities to the U.S.” was organised by the Office of International relations on 12th of April for the IIT Indore faculty members, postdoc and research scholars. Dr. Ryan Pereira, Regional Officer of USIEF, the Fulbright Commission in India himself came to deliver the same.\n \n USIEF administers the Fulbright-Nehru and other Fulbright Fellowships for Indian and U.S. citizens; provides educational advising services on U.S. higher education; and promotes linkages between institutions of higher education in the U.S. and India. Fulbright-Nehru Fellowships are jointly funded by the Government of India and the Government of U.S. For Indian citizens, they offer fellowships for teaching, research and professional development in the United States of America.",
  },
  {
    title: "BTech final year students' team  led by its faculty Dr. Gourinath Banda from CSE dept. have been awarded the prestigious, 'Global Best M-Gov Gold Awards' ",
    image: btech_dubai,
    desc: "BTech final year students' team (Ms. Niyati Totala, Mr. Neel Parikh and Mr. Bhoomil Gohel) led by its faculty Dr. Gourinath Banda, have been awarded the prestigious, \"Global Best M-Gov Gold Awards\" at the World Government Summit 2023, which was held in Dubai from 13th to 15th February 2023. The team competed with more than 1000 teams from around the world to earn this honor. The name of the digital transformation product is, \" Block Bill\""
  },
  {
    title: "Breaking Stigma held on 11th of February, 2023",
    image: BreakingStigma,
    desc: "“Breaking the Stigma” was a series of talk sessions that was initiated by the International Relations team within SIRC, to get successful alumni who are currently pursuing or have previously gone through some sort of postgraduate education from a foreign university, or are employed in a foreign land, to connect with the students that are currently part of IIT Indore, be it B.Tech., M.Tech., M.Sc. or PhD., to guide the ones that are interested in doing something similar. These talks were conducted to clear some misconceptions and doubts that students usually have about going abroad and getting some form of a higher level of education, and then potentially going for a job. These talks were met with good participation from the students' side, as the Q&A sessions at the end of the talk were met with a lot of questions from the students, which were answered by our speaker in great depth and with additional information.",


  },
  {
    title: " IIT Indore signed an MoU with Kwansei Gakuin University, Japan on 21st of February 2023.",
    image: kwansei,
     desc: "Professor Miharu Yui from Kwansei Gakuin University, Japan visited us on 21st of February and signed an MoU with us. We wish to enhance relations between the two institutions and to develop academic and cultural interchange in the areas of education, research, and other activities, and agree to cooperate and work together toward the internationalization of higher education through this partnership. \n",
  },
  {
    title: "Consul-General of Singapore Embassy, Mr. Cheong Ming Foong visited IIT Indore on 13th February, 2023.",
    image: cheongming,
    desc:"Mr.Cheong Ming Foong ,consul-General of Singapore Embassy,recently visited IIT indore on 13th February,2023.The mission of the Consulate-General is to safeguard the interest of Singaporeans visiting, living and working in the six states under its charge and to promote political, economic and cultural links between Singapore and India.",
    
  },
  {
    title: "Prof. Michael Gozin from Tel Aviv University, Israel visited IIT Indore on 6th February 2023.",
    image: israel,
    desc: "On the 6th of February 2023, IIT Indore had the distinct honor of hosting Professor Michael Gozin, a renowned figure in the world of chemistry and academia. Professor Gozin, hailing from Tel Aviv University in Israel, brought his wealth of knowledge and expertise to our campus, leaving an indelible mark on the academic community. His visit provided a unique opportunity for students and faculty alike to engage with a distinguished scholar and gain insights into cutting-edge research in chemistry. The event featured a stimulating lecture and interactive discussions that enriched our understanding of the latest advancements in the field. Professor Gozin's visit not only strengthened international academic ties but also inspired a new generation of scientists at IIT Indore to pursue excellence in their research endeavors. It was a truly enlightening experience for all involved, fostering collaboration and furthering the spirit of scientific inquiry.",





    
  },
  {
    title: " Prof. Bala Ramani from the University Office, International Affairs of University of Hanover (LUH), Germany visited IIT Indore on 1st February 2023.",
    image: bala_ramani,
    desc: "On the 1st of February 2023, IIT Indore had the distinct pleasure of hosting Professor Bala Ramani from the University Office, International Affairs of the University of Hanover (LUH) in Germany. Professor Ramani's visit marked a significant milestone in fostering international collaborations and academic partnerships. During her visit, she engaged in productive discussions with our faculty and students, focusing on potential avenues for academic cooperation, joint research initiatives, and student exchange programs between IIT Indore and LUH. Her expertise in international affairs and deep knowledge of global education systems greatly enriched our understanding and opened doors for meaningful collaboration. Professor Ramani's visit exemplified the commitment of both institutions to promoting cross-cultural learning and research, enhancing the global academic landscape, and strengthening the ties between IIT Indore and the University of Hanover.",
  },
  
  {
    title: "12 students of IIT Indore awarded the MITACS GLOBALINK RESEARCH INTERNSHIP 2023",
    image: MITACS2023,
    desc: "Mitacs Globalink Research Internship is a competitive initiative for international undergraduates from various countries. From May to October of each year, top-ranked applicants participate in a 12-week research internship under the supervision of Canadian university faculty members in a variety of academic disciplines, from science, engineering, and mathematics to the humanities and social sciences. \n 12 students from IIT Indore have been selected in MITACS GRI 2023: \n •  Ujjwal Hendwe (B.Tech. CSE 3rd Year) - University of Montreal \n •  Aditya Guhagarkar (B.Tech. EE 2nd Year) - University of British Columbia \n •  Kshitij Bhat (B.Tech. ME 3rd Year) - Lakehead University \n •  Parth Dwivedi (B.Tech. CE 3rd Year) - York University \n •  Dhruv Parasrampuria (B.Tech. EE 2nd Year) - University of Calgary\n •  Soham Ghewari (B.Tech. ME 3rd Year) - Dalhousie University \n •  Amit Dhavale (B.Tech. ME 3rd Year) - University of British Columbia \n •  Chinmayi Adoni (B.Tech EE 3rd Year) - University of Windsor \n •  Shruti Modi (B.Tech CSE 3rd Year) - McGill University Montreal \n •  Nischit Hosamani (B.Tech CSE 3rd Year) - Athabasca University \n •  Sejal Kotian (B.Tech MEMS 3rd Year) - Universite Inrs-Varennes \n •  Jitin Kumar (B.Tech MEMS 3rd Year) - University of Toronto \n We congratulate them on this achievement and wish them all the best for their internship. \n",
  },
  {
    title: "Ms. Priya Ghosh received “DAAD Fellowship”",
    image: Priya_Ghosh,
    desc: "A student from Prof. Avinash Sonawane's BSBE Lab, Ms. Priya Ghosh (PhD scholar) received DAAD Fellowship. She will work in Prof. Ulrich E. Schaible's lab at Leibniz Lung Centre, Borstel Germany. (October2023- September 2024). We congratulate her on this incredible achievement.",
  },
  {
    title: "Mr. Puneet Gupta has been awarded with the Charpak lab Scholarship",
    image: Puneet_gupta_image,
    desc: "The Charpak lab Scholarship is designed for Indian students enrolled in an Indian institution at the Bachelor's or Master's degree levels, planning to undertake a training/research project at a French laboratory or institution during their academic break (between April-May and July/August). We are thrilled to announce that from IIT Indore, Mr. Puneet Gupta, a BTech 3rd year student from Mechanical Department, has been selected for this fellowship and will pursue his summer research internship at the Centre for Nanoscience and Nanotechnology, Université Paris-Saclay. We congratulate him on this incredible achievement.",
  },
  {
    title: "Ms. Kanchan Samadhiya (PhD scholar) has been awarded with SING Funds (Indo-German Science & Technology Centre)",
    image: Kanchan_Samadhiya,
    desc: "We are thrilled to announce that from IIT Indore, Ms. Kanchan Samadhiya's application submitted under the SING initiative for “Biohydrogen: A carbon neutral energy vector from purple non-sulphur bacteria” has been positively reviewed and recommended for funding. We congratulate her on this incredible achievement.",
  },
  {
    title: "Session on \"German Research Landscape and Funding Possibilities\"",
    image: grlafp,
    desc: "An interactive session on German Research Landscape and Funding Possibilities was conducted by the Office of International relations on 16th of March for the IIT Indore faculty members. The team came from the DAAD office to deliver the same.",
  },
  {
    title: "Session on Study in Germany (Master + PhD) and DAAD scholarships",
    image: sigmtphd,
    desc: "An interactive session on Study in Germany (Master + PhD) and DAAD scholarships was conducted by the Office of International relations on 16th of March for the IIT Indore students. The team came from the DAAD office to deliver the same. It was attended by more than 70 students in person at campus.",
  },
  {
    title: "MoU signed between IIT Indore and Julius-Maxmilians University Würzburg in Germany",
    image: MoUJMU,
    desc: "On March 13, JMU Vice President Doris Fischer, IITI Director Suhas S. Joshi, and Avinash Sonawane, IITI Dean of International Relations, signed a memorandum of understanding in Germany which will provide for the exchange of students and faculty members as well as the expansion of joint research work.",
  },
  {
    title: "Mr. Jenish Shah has been awarded with the Charpak Exchange Scholarship 2023 (Spring Session)",
    image: jenish,
    desc: "The Charpak Exchange Scholarship Program of the French Embassy in India is aimed at Indian Bachelors or Masters students leaving for an exchange program in France for a duration of one semester (between January to June). We are thrilled to announce that from IIT Indore, Mr. Jenish Shah has been selected for this scholarship. He is a BTech final year student of Electrical Engineering and he shall be visiting France in January in Institut Mines-Télécom Atlantique for his exchange. We congratulate him on this incredible achievement.",
  },
  {
    title: "Delegates from the University of Sussex, UK visited IIT Indore ",
    image: international_visit_sussexUK,
    desc: "Ms. Li Camm, Head of Global Partnerships along with Mr. Neil Cantwell, Global Partnerships Manager at the University of Sussex, England, UK visited IIT Indore on 24th of November to discuss possible collaboration with IIT Indore. The interaction included core-supervision, student/ faculty/ staff exchange, and various other programs that could be started together.",
  },
  {
    title: "Dr. Ananya Ghoshal got selected by the U.S. Consulate General, Mumbai, for its 'Alumni Circles' program.",
    image: ananya,
    desc: "Dr. Ananya Ghoshal has been selected by the U.S. Consulate General, Mumbai, for its 'Alumni Circles' program. Through a jury process, the Consulate has selected a few early career U.S. Department of State Exchange Alumni based in Western India ( Maharashtra, Goa, Gujarat, Chhattisgarh, and Madhya Pradesh) to work in the field of Education. For the next year, Dr. Ananya's team will work on leveraging partnerships with foreign institutions to address the needs and aspirations of young students regarding the future of learning and work.",
  },
  // {
  //   title:
  //     "Call for applications from the International students for PhD program for the Academic Year 2022-2023",
  //   image: pgphdpdfimg,
  //   desc: () => (
  //     <a href={pgphdpdf} target="_blank" rel="noreferrer">
  //       Program details
  //     </a>
  //   ),
  // },
  {
    title: "PhD scholars Ms. Bhanu Priya and Mr. Ankit Kumar visited the University of Alberta under Prof. Sanjay Singh's joint research project (SICI-SICRG)",
    image: uofa,
    desc: "Ms. Bhanu Priya and Mr. Ankit Kumar, PhD students at Department of Chemistry, IIT Indore who are currently working under the supervision of Dr. Sanjay K. Singh visited Department of Chemical and Mechanical Engineering, University of Alberta, Canada in the research group of Prof. Samir H. Mushrif under SICI-SICRG exchange research project entitled “A synergistic experimental and computational approach to develop novel catalytic processes for upgrading agri-waste biomass/bio-oil to biofuel”. Duration of visit was one month starting from September 17 th to October 18 th , 2022. At University of Alberta, they interacted with the group members of Dr. Mushrif's group, attended group meetings, visited research laboratories in the Chemical Engineering Department. Along with that, they learnt basic concepts of computational chemistry and hand on experience with DFT modelling tools (Gaussian and VASP)."
  },
  {
    title: "Prof. Nirmala Menon receives Indo-Swiss Bilateral Project on \"Digital Twins: negotiating identity and translocated heritage in the global age\"",
    image: nirmala,
    desc:
    "It is with great pride and enthusiasm that we announce Professor Nirmala Menon's achievement in securing the prestigious Indo-Swiss Bilateral Project on Digital Twins: negotiating identity and translocated heritage in the global age. This remarkable accomplishment underscores Professor Menon's dedication to cutting-edge research and the significance of her work in the field of digital twins and cultural heritage. This project, which fosters collaboration between India and Switzerland, holds the promise of groundbreaking insights into how digital technologies can be harnessed to negotiate issues of identity and heritage in an increasingly globalized world. Professor Menon's leadership in this initiative is sure to make a substantial impact, not only in the academic community but also in the broader context of cultural preservation and understanding. We look forward to the innovative discoveries and outcomes that will emerge from this exceptional endeavor.",
  },
  {
    title: "Prof. Pascal Maussion from Toulouse INP visited IIT Indore",
    image: visit14102022,
    desc: "Prof. Maussion from Institut national polytechnique de Toulouse (National Polytechnic Institute of Toulouse) and Researcher with CNRS Lab LAPLACE, Laboratory for Plasma and Conversion of Energy, France and the Vice President of International Affairs visited IIT Indore on October 14, 2022 to deliver a talk on “Frugal Innovations for Rural Electrification”. Moreover, he interacted with the IIT Indore faculties regarding the research exchange and collaboration. The visit concluded with the guest Professor visiting our laboratories to have an insight of our facilities."
  },
  {
    title: "A talk on “Modeling Malaria Transmission in Nepal: Impact of Imported Cases through Cross-Border Mobility” by Mr. Ramesh Gautam, Tribhuvan University, Ratna Rajya Laxmi Campus, NEPAL",
    image: talk2,
    desc: "On 11th October, 2022 at 3:00 PM Mr. Ramesh Gautam delivered a talk on “Modeling Malaria Transmission in Nepal: Impact of Imported Cases through Cross-Border Mobility”. Mr. Ramesh Gautam (faculty member of Tribhuvan University, Ratna Rajya Laxmi Campus, NEPAL) is on a visit to IIT Indore in the Mathematics department for one- month of collaborative research works through CIMPA scheme.",
  },
  {
    title: "A talk on “Transmission Dynamics of COVID-19 in Nepal: Mathematical Models for Effective Control” by Mr. Khagendra Adhikari, Tribhuvan University, Amrit Campus, NEPAL",
    image: talk1,
    desc: "On 30th September, 2022 at 5:00 PM Mr. Khagendra Adhikari delivered a talk on “Transmission Dynamics of COVID-19 in Nepal: Mathematical Models for Effective Control”. Mr. Khagendra Adhikari (faculty member of Tribhuvan University, Amrit Campus, NEPAL) is on a visit to IIT Indore in the Mathematics department for one- month of collaborative research works through CIMPA scheme."
  },
  {
    title: "A public talk on \"India's Tryst with Global Destiny\" by Ambassador \"Manjeev Singh Puri (Retd.)\"",
    image: tmsp,
    desc: "The Ministry of External Affairs, Govt. of India is organizing a commemorative lecture series entitled \"India@75: Videsh Niti Distinguished Lecture Series\", as a part of the celebrations of 75 years of India's Independence and under this series Former Ambassador Manjeev Singh Puri delivered a public talk on \"India's Tryst with Global Destiny\" at IIT Indore",
  },
  {
    title: "Prof. Sundararajan (Retd.) Nanyang Technological University, Singapore visited IIT Indore",
    image: sundararajan,
    desc: "Prof. Narasimhan Sundararajan visited IIT Indore as the speaker of the workshop on on \"How to Improve Your Research Skills\" from 8th of September to 10th of September 2022. Workshop was attended by 100+ research scholars and faculties from the IIT Indore campus in person along with 200+ participants from other IITs, NIT, and IIITs virtually on the WebEx platform.\n\n Brief about the speaker: Prof. N. Sundararajan has worked in ISRO, Trivandrum, India as a Control System Designer to Director, Launch Vehicle Design Group contributing to the design and development of the Indian satellite launch vehicles SLV3, ASLV, PSLV and GSLV. His major achievements include working as the Project Engineer (Mission) for the first Indian Satellite Launch Vehicle project SLV3 team working directly under Dr. A. P. J. Abdul Kalam. Post that, he worked in the School of Electrical and Electronic Engineering, Nanyang Technological University, Singapore, as a professor and retired from that position.",
  },
  {
    title: "Prof. Abhinav Kranti awarded with the ASEM-DUO Belgium-Wallonia-Brussels fellowship award",
    image: abhinavsir,
    desc: "The advent of programmable field effect transistor (PrFET), capable of performing a multitude of functions with lower transistor count, has opened up exciting possibilities for next generation nanoelectronic systems. The ASEM-DUO Belgium-Wallonia-Brussels fellowship award to Professors Jean-Pierre RASKIN and Abhinav KRANTI aims to support technology development of PrFET for high-speed and analog/RF applications.",
  },
  {
    title: "Prof. Avinash Sonawane, Dean, International Relations, IIT Indore has been appointed as 'DAAD Research Ambassador' for 2022-25.",
    image: profSonawane,
    desc: "Prof. Sonawane has been appointed as 'DAAD Research Ambassador’ for a three-year period between 2022-25. 'DAAD Research Ambassadors' are academicians and scientists who have a long standing relationship with Germany and are interested in promoting Germany as a destination for higher learning and scientific cooperation by sharing their experience, mentoring students and colleagues.",
  },
  {
    title: "Prof. Sarika Jalan Presents Keynote Talk at Dynamical Days Europe",
    image: sarika,
    desc: "Delivered Keynote on-site plenary talk in Dynamical days Europe, held in Aberdeen, August 2022. \nDelivered a series of lectures (on-site) for graduate students at TU Berlin, Germany on her recent results on higher-order interactions in December 2022."
  },
  {
    title: "International Students Visit to IIT Indore in August 2022",
    image: InternationalStudentsVisit,
    desc: "32 International students from 11 different countries including USA, UK, Germany, Brazil, Belgium, Mexico, Egypt, Trinidad & Tobago, Azerbaijan and South Africa, visited IIT Indore on 4th August 2022 under Global BP STEM Academic program in association with American Field Service (AFS), India. The visit was coordinated by Emerald Heights International School, Indore which is an active member of AFS since 2012 under the Indore chapter (MP) India.",
  },
  {
    title: "Prof. Mushrif, University of Alberta, Canada visited IIT Indore",
    image: canadaVisitIITIndore,
    desc: "Prof. Samir H. Mushrif visited IIT Indore on July 19, 2022 under the SICRG, SICI Funded project with the IIT Indore host Prof. Sanjay K. Singh. Prof. Mushrif delivered a talk on \"Origin of Solvent effect\" and conducted a session with IIT Indore students on \"Opportunities in Higher Studies in Canada\".",
    link: "http://r1249866.sici.org.in/c/v?e=14C9048&c=13124A&t=0&l=4BEFF7A9&email=NdfXrTGzK3KxbM0NuRBHpBKiVoqX%2B%2FWS",
  },
  {
    title: "\"Study in France\" Seminar held on 5th of August, 2022",
    image: studyInFrance5Aug22,
    desc: "Miss. Divya Saxena, head of the Bhopal office for Campus France Madhya Pradesh, Chhattisgarh and Nagpur division - The Embassy of France in India visited IIT Indore to give a short presentation session. It was an interactive session that was organized for IIT Indore students to entail about the \"Higher Education, Research, Internships and Scholarship Opportunities in France\". A strength of 100+ students joined the same in person.",
  },
  {
    title: "Dr. Hem Chandra Jha, Associate Professor got SING Fellowship Award",
    image: HemaChandraJha,
    desc: "Indo-German Science & Technology Centre (IGSTC) Awarded \"Small Immediate Need Grants (SING)\" to Dr. Hem Chandra Jha, Indian Institute of Technology, Indore to undertake research on \"Unrevealing the role of peroxisome in Epstein Barr virus-mediated infections\" with a total budget of  7,70,000/- lakhs for a period of 12 months.",
  },
  {
    title:
      "First Group Monitoring Workshop of SERB Start-up Research Grant - Life Sciences (SRG-LS) from 4th to 5th July 2022",
    image: serb,
    desc: 'IIT Indore hosted the first Group Monitoring Workshop of SERB Start-up Research Grant - Life Sciences (SRG-LS) from 4th to 5th July 2022. Prof. Suhas S. Joshi, Director, IIT Indore delivered the inaugural address and welcomed all the dignitaries and Principal Investigators. The inaugural session was graced by Prof. Shiv Mohan Singh (Scientist G), SRG Programme Advisor, SERB-DST, Govt. of India, Prof. Manoj Prasad from National Institute of Plant Genome Research, New Delhi, Chairman of the SRG-LS Committee, and Dr Pramod Kumar Prasad, Programme Officer, SRG-Life Sciences, SERB-DST. The event was coordinated by the International Relations (IR) office under the leadership of Prof. Avinash Sonawane, Dean of International Relations. 23 Expert Committee Members and 85 Principal Investigators from various Institutions attended the workshop.During the workshop, SERB monitored and evaluated the progress of approximately 85 ongoing projects in Life Sciences submitted under the Start-up Research grant program. The Start-up Research Grant (SRG) is meant to enable researchers working in frontier areas of science and engineering to establish themselves and move on to the mainstream core research grant.Prof. Suhas S. Joshi briefed about the course planned for start-up and said "The Institute has around 75 technologies, which have emerged from the research of Professors and students at the Institute. We have documented them and made a brochure of these technologies and one of the technologies is up for commercialisation. Apart from this, 17 patents have been filed out of these technologies and then several patents are in the pipeline. We are very progressive not only in research but also in terms of technology development, its nurturing and licensing. I hope this workshop conducted by SERB will be very fruitful to the budding researchers as it will enable the technology developed to not remain confined to the laboratories but move to an advanced form."',
  },
  {
    title:
      "Prof. Sandeep Chaudhary to lead a team of researchers at NPI, Russia as a winner of the ninth mega grant competition of the Government of the Russian Federation",
    image: sandeep,
    desc: "Prof. Sandeep Chaudhary is one of the 30 winners of the ninth mega grant competition of the Government of the Russian Federation. The program aims to create world-class research laboratories in Russian universities and scientific organizations under the guidance of leading scientists (https://p220.ru/en/). The host Institute NPI Russia has been awarded funding of 90 Million Rubles to establish the laboratory on “Fuel Waste Recycling” and carry out research on “Carbon-neutral technologies for recycling large-tonnage fuel wastes to produce functional geopolymer materials” for green and energy-efficient construction under his supervision and guidance.",
  },
  {
    title:
      "Minu Treesa Abraham, Civil Engineering got selected for the prestigious Humboldt Fellowship",
    image: rwthUniv,
    desc: "Ms. Minu Treesa Abraham from the Department of Civil Engineering got selected for the prestigious Humboldt Fellowship for Postdocs. Ms. Minu will be joining the Model-based Development in Computational Engineering (MBD) group at RWTH Aachen University, Germany, with Prof. Julia Kowalski.",
  },
  {
    title: "Project of IIT Indore selected for U.S. Alumni Micro Grants 2022",
    image: ananya,
    desc: "Dr. Ananya Ghoshal's project on education has been selected as part of the prestigious Alumni Micro Grants Competition, 2022. Spearheaded by the US Embassy, this grant aims to promote positive change in Indian society through micro - and macro-scale social impact projects in the fields of environment, education, women and youth empowerment, and entrepreneurship.",
  },
  {
    title: "USIEF: Fulbright outreach webinar held on 18th of April at IIT Indore",
    image: fulbright_1,
    desc: "Miss. Suranjan Das, Program & Alumni Manager, United States-India Educational Foundation (USIEF) Fulbright Commission in India visited IIT Indore on 18th April 2022 to take an outreach webinar. It was an interactive session that was organized for IIT Indore students and Faculty members to entail about the Fulbright-Nehru, and other Fulbright opportunities for study, research, teaching and professional development in the U.S.",
  },
  {
    title: "Visit of Norwegian delegates to IIT Indore on 5th April 2022",
    image: norwegianDelegates,
    desc: "Prof. Michael Rygaard Hansen, Dean, and Prof. Linga Reddy Cenkeramaddi from the University of Agder, Grimstad, Norway, along with Dr. Maan Singh Sidhu from Royal Norwegian Embassy, New Delhi visited IIT Indore on 5th April 2022. An interactive session was organized with the foreign delegates for enlightening IIT Indore students and Faculty members on opportunities at the University of Agder, Grimstad, Norway. IIT Indore- UiA, Grimstad will soon sign an MoU for International collaboration.",
  },

  {
    title:
      "Mr. Nehal Ahmad from NTUT, Taiwan joined the lab of Dr. M. Tanveer, Department of Mathematics",
    image: ntut,
    desc: "Mr. Nehal Ahmad from NTUT, Taiwan (also known as Taiwan Tech) joined the Mathematics lab at IIT Indore under the activity of MoU with NTUT, Taiwan, and IIT Indore. Dr. M. Tanveer, Department of Mathematics is also a co-supervisor for his PhD program.",
  },
  {
    title: "Welcome of Prof. Indrajith Nissanka and Dr. Yogeshwar Nath Mishra",
    image: welcome_news,
    desc: "International Affairs & Outreach office organized a welcome meeting on 24/03/2022 for Prof. Indrajith D. Nissanka, Senior Lecturer, Department of Mechanical Engineering, University of Moratuwa, Katubedda, Sri Lanka arrived at IIT Indore for Research Training under the Indian Science and Research Fellowship and Dr. Yogeshwarnath Mishra for SPARC project foreign faculty visit under SPARC project funding for investigating the ultrafast physics of flow and combustion during this visit at Spray and Combustion Laboratory, Mechanical Department IIT Indore.",
  },
  {
    title: "Ms. Palak Saket received India-UK “Newton Bhaba Phd placement fellowship”",
    image: hud_uk,
    desc: "We are thrilled to congratulate Ms. Palak Saket on being awarded the prestigious India-UK Newton Bhabha PhD Placement Fellowship. This is a remarkable achievement that not only recognizes her exceptional academic and research prowess but also signifies her potential to contribute significantly to the global scientific community. The Newton Bhabha Fellowship is a testament to the strong research and educational ties between India and the United Kingdom, fostering collaborations between scholars and institutions from both countries. Ms. Palak Saket's receipt of this fellowship demonstrates her commitment to advancing scientific knowledge and her potential to make a meaningful impact in her field. We look forward to witnessing the valuable research and collaborations that will emerge from this opportunity and wish her all the best in her academic journey.",
  },
  {
    title: "Dr. Venkatesh Chelvam visited University of Bordeaux and University of Nantes",
    image: chelvamBordeaux,
    desc: "Dr. Vankatesh Chelvam, Associate Professor, Chemistry visited French Institutes University of Bordeaux and University of Nantes sponsored by French Consulate (Dec.13-17, 2021),",
  },
  // {
  //   title: "Short-Term Research Program for International Academics and Scientists",
  //   image: image5,
  //   desc: "Short-Term Research Program for International Academics and Scientists (awarded for 1-3 months, can be extended to 6 months with a sabbatical) is for the highly qualified enthusiastic university teachers, established academics or scientists from the institutes of higher education from SAARC, AFRICAN, MIDDLE EAST, ASEAN, and other developing countries to provide them the opportunity for a short-term research work at IIT Indore. The program aims to promote the exchange of knowledge and building networking amongst the colleagues and the institutes.", // date: "Nov 15, 2021",
  //   name: "srp-ias",
  //   link: "/inbound/researchers/srp-ias",
  // },
  
  {
    title: "RIISSHE",
    image: riisshe,
    desc: "The Research Internship program (RIISSHE) aims to offer academic and research exposure to young dynamic international students from across the globe in the cutting-edge areas of research, technology and innovation of science, engineering and humanities. This program also aims to intensify the research aptitude of the students to effectively take up research as a career.",
    link: "/inbound/students/riisshe",
  },
  {
    title: "Virtual Meeting with Hungarian Institutes",
    image: Hungarian,
    desc: "Indian Institute of Technology Indore (IIT Indore), India successfully conducted a Virtual Scientific meeting with a team of scientists from various Hungarian Universities/institutions in association with the Embassy of Hungary, New Delhi on 15th November 2021 at 14:00 (IST) onwards. The objective of this scientific meeting was to brainstorm to promote and proliferate the scope of cooperation in research, technology development, innovation, and exchanges between IIT Indore and Hungarian Universities/institutions.",
    date: "Nov 15, 2021",
    name: "virtual-meet",
  },
  {
    title:
      "ASM-IUSSTF Indo — US Professorship-2021 and The International Union of Biochemistry and Molecular Biology (IUBMB) - Mid-Career Award-2021",
    image: midCareer,
    desc: "Dr. Mirza S Baig has received the ASM-IUSSTF Indo-US Professorship-2021 funded by the American Society for Microbiology (ASM) in partnership with the Indo-US Science & Technology Forum (IUSSTF). The Professorship recognizes scientists with distinguished contributions in the field of Microbiology and service to the profession. The prestigious recognition broadens scientific collaboration between India and the United States through the research and teaching partnerships.\nDr. Mirza S Baig has received the International Union of Biochemistry and Molecular Biology (IUBMB) Mid-Career Award. The award celebrates highly promising young investigators in biochemistry and molecular biology. This prestigious recognition has been granted to him for his work on the molecular mechanisms of Chronic inflammation associated with conditions like; cancer, arthritis, heart disease, diabetes, and dementia. This award has been granted to recognise his lifelong commitment to research and training in biochemistry. Dr. Baig will be a part of the renowned Pharmacology group at The University of Illinois at Chicago researching VE-PTP interaction with VE-cadherin thereby regulating endothelial junction permeability during Chronic inflammatory conditions.",
    date: "Oct 16, 2021",
    name: "asm-iusstf",
  },
  {
    title:
      "IIT Indore is participating in the PAN IIT Global Virtual Technology Summit PI-WOT 2021",
    image: virtualBooth,
    desc: "This summit is aimed to showcase the excellence of each IIT in academics, research, and innovations, and reaching out to the IIT Alumni, Industries, and Investors. We are also going to set up one virtual booth representing IIT Indore.\nThe IIT Exhibition Area is now open to receive visitors and will continue during the Summit (17th and 18th September) and close on 18th September when the Summit concludes.You can visit all the IIT Exhibition Area at any time ( 24*7 it is open) of your convenience. You may invite your colleague and friends to join the Summit and also visit the IIT Exhibition Area by sharing the following link with them to register for this event: https://piwot.paniit.org/register-now.php . Participation is open to all.",
    date: "Sep 13, 2021",
    name: "global-virtual-technology-summit",
  },
  {
    title:
      "Ms. Shalini Singh, Ph.D. Scholar, Dept. of Mechanical Engineering, IIT Indore has received the OVDF fellowship to pursue her research  studies in the University of Alberta.",
    image: uoa,
    desc: "Title of the Research work: “Customized Shape Memory Alloy (SMA) Structures using Plasma and Wire Arc Additive Manufacturing”\nMentoring Faculty from Canada: Prof. Ahmed Qureshi, Associate Professor, Faculty of Engineering, Mechanical Engineering Deptt., University of Alberta, Canada\nMentoring Faculty from IIT Indore: Dr. I. A. Palani, Department of Mechanical Engineering\nDuration:1 year (Jan. 2022-Jan. 2023)",
    date: "Sep 3, 2021",
    name: "shalini",
  },
  {
    image: BUL,
    title: "Forecasting crude oil futures prices by Prof. Date",
    desc: "Dr. Paresh Date, Reader and Director of Research in Mathematics, Brunel University, London, UK has given talk on “Forecasting crude oil futures prices using the Kalman filter and news sentiment” on August 27, 2021 under IR International Outreach event of IIT Indore. The talk was coordinated by Dr. Abhinoy K. Singh, Department of Electrical Engineering, IIT Indore.",
    link: "https://www.brunel.ac.uk/people/paresh-date",
    name: "crudeOil",
  },
  {
    image: NIDB,
    title: "National Institute of Design Bhopal visited IIT Indore",
    desc: "Officials from National Institute of Design, Bhopal visited International Affairs & Outreach office, IIT Indore on August 23, 2021. During the visit, we had a constructive discussion with NID team to extend our support to establish international office at NID Bhopal. ",
    name: "nidBhopal",
  },
  {
    image: FrenchConsultImg,
    title: "French Consulate General in Mumbai visited IIT Indore",
    desc: "Mrs. Sonia Barbry, French Consulate General in Mumbai and Mr. Thomas Simoes, Director, Alliance Francaise, Bhopal visited IIT Indore on March 18, 2021, and had fruitful discussions on escalating and strengthening the bilateral research work, exchange of students and faculties and many more aspects. ",
    name: "frenchConsulate",
  },
  {
    image: NSDImg,
    title: "IIT Indore celebrated National Science Day in virtual mode",
    desc: "IIT Indore is celebrating the National Science Day on Feb. 28, 2021, where several activities such as daily life physics experiments, chemical illusion, journey through cosmos, mathematical magic, biology talk, will be organized for school kids in virtual mode.",
    link: "https://www.freepressjournal.in/indore/indore-iit-indore-to-celebrate-national-science-day-in-virtual-mode",
    name: "nsd",
  },
  {
    image: MoU,
    title: "MoU signed with University of Alberta, Canada",
    desc: "IIT Indore and University of Alberta, Canada has signed an MOU to escalate the research activities and exchange on the mutually agreeable domains, covering the exchange of students, faculties and staffs, development of joint curriculum, organising joint workshops and conferences, and also to explore the possibilities of joint degree programmes. This MOU has been signed in 2021 and will remain valid for 5 years.",
    link: "https://clt1249866.bmeurl.co/C8589D3",
    name: "mouCanada",
  },
  {
    image: chemR,
    title: "Direct H2O2 synthesis",
    desc: "Catalysis group, IIT Indore and KIT Germany reported their work on shape-selective Pd3Pb nanocrystals based catalysts for direct H2O2 synthesis. Their findings inferred the tuning in electronic structure and lack of Pd ensembles retarted O-O cleavage.",
    link: "https://pubs.acs.org/doi/abs/10.1021/acscatal.0c03561",
    name: "h2o2",
  },
  {
    image: phyR,
    title: "Origin of natural and magnetic field induced polar order",
    desc: "The group of Dr. Sagdeo, IIT Indore and STFC Rutherford Appleton Laboratory, UK have reported the possible origin of natural and magnetic field induced switchable polar order near room temperature (RT) in PrFe1/2Cr1/2O3",
    link: "https://journals.aps.org/prb/abstract/10.1103/PhysRevB.104.035101",
    name: "magneticField",
  },
  {
    title: "Impact of the Digital Revolution on Higher Education",
    image: ai,
    desc: 'Dr. Aruna Tiwari, Associate Professor, CSE represented IIT Indore as an Artificial Intelligence expert in the Internationalist Podcast series on  "Impact of the digital revolution on higher education", organized by the Association of Commonwealth Universities, UK on June 8, 2021.',
    // date: "June 8, 2021",
    name: "digitalRevolution",
  },
  {
    title: "Chemistry Talks",
    image: chem_talks_news,
    desc: " Chemistry Talks: India celebrates the National Science Day on 28th February to mark the discovery of the Raman effect by Sir C. V. Raman On this occasion, the Department of Chemistry ,Indian Institute of Technology Indore is organizing Chemistry Talks.In the month of February, chemistry-based research talks from several eminent speakers across the world have been scheduled.\nChemistry Lectures aim to provide a platform for eminent chemists from all over the world to share and discuss wider aspects of the domain of chemical sciences and allied areas. The webinar is expected to provide a unique opportunity to establish new and ever-lasting bonding between the researchers, and inspire the young students to explore new ways to understand chemistry and its interface.",
    // date: "January 28, 2021",
    name: "chemTalks",
  },
];