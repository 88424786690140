export const data = [
    {
        "name": "Mr. Dipak Paudel",
        "country": "Nepal",
        "program": "PhD (BSBE)",
        "text": "Mr. Dipak Paudel is an international PhD candidate selected for the Short-Term Collaborative Research Program at the Indian Institute of Technology Indore's BSBE Department. During his three-month tenure, he worked under the supervision of Dr. Hem Chandra Jha, an Associate Professor in the BSBE department at IIT Indore. He is currently enrolled in the Chemistry Department at Tribhuvan University, Nepal.",
        "VidLink": "https://www.youtube.com/embed/f2s7_tN8sLk"
    }
,    
    {
        "name": "Mr. Gedfaw Asmare Tafete",
        "country": "Ethiopia",
        "program": "PhD (Chemistry)",
        "text": "Mr. Gedfaw Asmare Tafete is an international PhD candidate selected for the Short-Term Collaborative Research Program at the Indian Institute of Technology Indore's Department of Chemistry. During his three-month stay, he worked under the supervision of Professor Shaikh Mobin. He is currently enrolled in the Chemical Engineering Department at Bahir Dar University, Ethiopia.",
        "VidLink": "https://www.youtube.com/embed/0WFg42_O8wA"
    }
,    
    {
        "name": "Mr. Shimelis Tamene Gobena",
        "country": "Ethiopia",
        "program": "PhD (Mechanical Engineering)",
        "text": "Mr. Shimelis Tamene Gobena is an international PhD candidate selected for the Short-Term Collaborative Research Program at the Indian Institute of Technology Indore's Department of Mechanical Engineering. He worked under Professor Neelesh Kumar Jain for three months during his stay. He is currently enrolled in the Mechanical Engineering Department at Addis Ababa Science and Technology University, Ethiopia.",
        "VidLink": "https://www.youtube.com/embed/JBBJIvORjhg"
    }
,    
    {
        "name": "Mr. Youba Raj Poudyal",
        "country": "Nepal",
        "program": "PhD (Computer Science Engineering)",
        "text": "Mr. Youba Raj Poudyal is currently a student at the Department of Computer Science Engineering, Kathmandu University, Nepal. He is now an International PhD Student at the Department of Computer Science Engineering at IIT Indore, selected under the Short-Term Collaborative Research Programme for 3 months. He is working under the guidance of Dr. Neminath Hubballi, an Associate Professor in the CSE department at IIT Indore.",
        "VidLink": "https://www.youtube.com/embed/8vbMPEh2XH4"
    }
,    
    {
        "name": "Mr. Tewodros Kassahun Abebe",
        "country": "Ethiopia",
        "program": "PhD (Civil Engineering)",
        "text": "Mr. Tewodros Kassahun Abebe is an International PhD Student at the Department of Civil Engineering at IIT Indore, selected under the Short-Term Collaborative Research Programme for a duration of 3 months. He worked under the guidance of Dr. Gourab Sil, an Assistant Professor in the Civil Engineering department. He is currently a student at Bahir Institute of Technology, Bahir Dar University, Ethiopia.",
        "VidLink": "https://www.youtube.com/embed/ErShBB8cKPk"
    }
,    
    
    {
        "name": "Dr. Solomon Abebe",
        "country": "Ethiopia",
        "program": "International Postdoctoral Researcher",
        "text": "International Post Doctoral researcher at IIT Indore ",
        "VidLink": "https://www.youtube.com/embed/rjYbfgMrJSY"
    }
,    
    {
        "name": "Mr. Dr Paul Ahoutou",
        "country": "Cote D' Ivorie",
        "program": "Visiting Scholar",
        "text": "Mr. Dr Paul Ahoutou visited IIT Indore as a Visiting Scholar for three months. As he completed his work, he expressed gratitude to Sir S. Ran for the opportunity to conduct research, meet people, and exchange culture and science. He also thanked his scientist colleagues and students for their support in achieving research results. ",
        "VidLink": "https://www.youtube.com/embed/JoYfEclaVmE"
    }
 ,    
    {
        "name": "Mr. Arjun Hasibuan",
        "country": "Indonesia",
        "program": "Research Collaborator",
        "text": "Student (PhD-Short Term Exchange) from Indonesia at IIT Indore.",
        "VidLink": "https://www.youtube.com/embed/X2wEVqEJLxA"
    }
,    
    {
        "name": "Mr. Semen Gulevich",
        "country": "Russia",
        "program": "International PhD Student",
        "text": "Mr. Semen Gulevich is an International PhD Student of Dr. Irina Alexandrovna Kurzina, Professor at the Department of Physical and Colloid Chemistry, Tomsk State University, Tomsk Oblast, Russia. Mr. Gulevich joined IIT Indore for a period of 45 days in November and December month of 2024 under Prof. Ajay Kumar Kushwah in a joint project with Russia Research Group under DST-MSHE funding.",
        "VidLink": "https://www.youtube.com/embed/Vt487hH2_rs"
    }
    
    ,{
        "name": "Mr. Tesfaye Geneti Kenate",
        "country": "Ethiopia",
        "program": "International PhD Student",
        "text": "Mr. Tesfaye Geneti Kenate is an International PhD Student at the Department of Civil Engineering of Indian Institute of Technology Indore selected under the short-term collaborative research program (Inbound). He came here for a short duration of 3 months initially but extended his stay for a year as he worked under the guidance of Prof. Sandeep Chaudhary, a professor at the Civil engineering department at IIT Indore. He currently is a student at Jimma University, Ethiopia.",
        "VidLink": "https://www.youtube.com/embed/1yWiSGZuQYM"
    },
    
    {
        name: "Mr. Ryosuke Omura",
        country: "Japan",
        program: "Master’s Student",
        text: "Mr. Ryosuke Omura is an International Masters Exchange Student at the Department of Electrical Engineering of the Indian Institute of Technology Indore. He came here for a month under the guidance of Prof. Vipul Singh, a professor in the Electrical Engineering department at IIT Indore. He currently is a student at Kyushu Institute of Technology, Japan.",
        VidLink: "https://www.youtube.com/embed/CF5fxUhFXdI"
    },
  
    {
        name: "Ms. Brigita De Vega",
        country: "United Kingdom",
        program: "Short-term Research Fellowship PhD",
        text: "Ms. Brigita De Vega is an International Short-term Research Fellowship PhD Student at the Department of Chemistry of Indian Institute of Technology Indore. She is native to Indonesia and has joined IITI for a period of 3 months. She is currently pursuing her PhD from University College London, UK.",
        VidLink: "https://youtube.com/embed/FaXrrZsYtdc"
    },
    {
        name: "Ms. Halima Benali",
        country: "Morocco",
        program: "Short-term Research Fellowship PhD",
        text: "Ms. Halima Benali is an International Short-term Research Fellowship PhD Student at the Department of Mathematics of Indian Institute of Technology Indore. She is native to Morocco and has joined IITI for a period of 3 months. She is currently pursuing her PhD from Sultan Moulay Slimane University, Morocco.",
        VidLink: "https://www.youtube.com/embed/GfjY2ouPGrw"
    },
    {
        name: "Ms. Dibyashree Poudyal",
        country: "Nepal",
        program: "Short-term Research Fellowship PhD",
        text: "Ms. Dibyashree Poudyal is an International Short-term Research Fellowship PhD Student at the Department of Civil Engineering of Indian Institute of Technology Indore. She is native to Nepal and have joined IITI for a period of 3 months. She is currently pursuing her PhD from Infrastructure University Kuala Lumpur, Malaysia.",
        VidLink: "https://www.youtube.com/embed/sXmabrXyPHc"
    },
    {
        name: "Mr. Raghu Nath Prajapati",
        country: "Nepal",
        program: "Short-term Research Fellowship PhD",
        text: "Mr. Raghu Nath Prajapati is an International Short-term Research Fellowship PhD Student at the Department of Civil Engineering of Indian Institute of Technology Indore. He is native to Nepal and have joined IITI for a period of 3 months. He is currently pursuing his PhD from Infrastructure University Kuala Lumpur, Malaysia.",
        VidLink: "https://www.youtube.com/embed/tlhDO6AmTeM"
    },
    {
        name: "Mr. Johirul Islam",
        country: "Finland",
        program: "PhD",
        text: "Mr. Johirul Islam is a PhD exchange student at the Department of Computer Science and Engineering of Indian Institute of Technology Indore. He is native to Finland and have joined IITI for a period of 3 months.",
        VidLink: "https://www.youtube.com/embed/vqlAqgq4fFI"
    },
    {
        name: "Mr. Bimal Bashyal",
        country: "Nepal",
        program: "M.Tech. (MSD)",
        text: "Mr. Bimal Bashyal is an MTech Self-funded student of MSD Department of the Indian Institute of Technology Indore. He is native to Nepal and have joined the program for the academic year 2022-24.",
        VidLink: "https://www.youtube.com/embed/Pp9YeQWaU5Q"
    },
    {
        name: "Ms. Lidiya Kumssa Fekadu",
        country: "Ethiopia",
        program: "MTech (Space Engineering)",
        text: "Ms. Lidiya Kumssa Fekadu is enrolled in MTech program (Space Engineering) for the Academic year 2022-24. She is native to Ethiopia and have joined the Indian Institute of Technology Indore through Study in India Fellowship.",
        VidLink: "https://www.youtube.com/embed/6vjxD6krwIA"
    },
    {
        name: "Mr. Arifuzzaman Khan",
        country: "Bangladesh",
        program: "Ph.D. (HSS)",
        text: "Mr. Arifuzzaman Khan is a Ph.D. scholar of HSS Discipline at Indian Institute of Technology Indore. He is native to Bangladesh and have enrolled himself into the program in 2022 through SAARC Finance - RBI Scholarship.",
        VidLink: "https://www.youtube.com/embed/neyL0PauNog"
    },
    {
        name: "Mr. Tim Seedorf",
        country: "Germany",
        program: "PhD (Chemistry)",
        text: "Mr. Tim Seedorf is a PhD Exchange Student at the Department of Chemistry, Indian Institute of Technology Indore. He is native to Germany and have come for a period of 6 Weeks (2022-23) to our institute.",
        VidLink: "https://www.youtube.com/embed/Iea62B9NjnM"
    },
    {
        name: "Mr. Akash Yadav",
        country: "Nepal",
        program: "MTech (PIE)",
        text: "Mr. Akash Yadav is an MTech student of PIE Department of the Indian Institute of Technology Indore. He is native to Nepal and have joined the program for the academic year 2021-23 through Nepal Scholarship.",
        VidLink: "https://www.youtube.com/embed/iHpWzXqU3hg"
    },
    {
        name: "Mr. Nehal Ahmad",
        country: "Taiwan",
        program: "PhD (Mathematics)",
        text: "Mr. Nehal Ahmad is a PhD Exchange Student at the Department of Mathematics, Indian Institute of Technology Indore. He is native to Taiwan and have come for a period of 9 Months (2021-22) to our institute.",
        VidLink: "https://www.youtube.com/embed/JLxoWHLq61U"
    },
    {
        name: "Mr. Akah Precious Chiemena",
        country: "Nigeria",
        program: "MTech (CSP)",
        text: "Mr. Akah Precious Chiemena is an MTech student at Indian Institute of Technology Indore enrolled for the academic year 2021-23 in CSP branch. He is native to Nigeria and has come through the ICCR Fellowship.",
        VidLink: "https://www.youtube.com/embed/2Ur38lintdE"
    },
    {
        name: "Mr. Pawan Singh",
        country: "Nepal",
        program: "Ph.D. (HSS)",
        text: "Mr. Pawan Singh is a Ph.D. scholar of HSS Discipline at Indian Institute of Technology Indore. He is native to Nepal and have enrolled himself into the program in 2021 through Nepal Scholarship.",
        VidLink: "https://www.youtube.com/embed/MlLchXnXRQA"
    },

    {
        "name": "Ms. Lidiya Kumssa Fekadu",
        "country": "Ethiopia",
        "program": "MTech (Astronomy and Astrophysics)",
        "text": "Ms. Lidiya Kumssa Fekadu is enrolled in MTech program (Space Engineering) for the Academic year 2022-24. She is native to Ethiopia and have joined the Indian Institute of Technology Indore through Study in India Fellowship.  ",
        "VidLink": "https://www.youtube.com/embed/6vjxD6krwIA"
    }
,   
]